import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const BookingOnline = ({ rate }) => {
  const bookingData = useStaticQuery(graphql`
    query BookingOnlineQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "bookOnline" } } }
      ) {
        edges {
          node {
            frontmatter {
              bookingLogo {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
              bookingButton {
                url
                title
              }
            }
          }
        }
      }
    }
  `)
  const { bookingLogo, bookingButton } =
    bookingData.allMarkdownRemark.edges[0].node.frontmatter
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="action_blk">
          <div className="action_text_img">
            <a href="#">
              <GatsbyImage
                image={bookingLogo.childImageSharp.gatsbyImageData}
                alt="Booking background"
              />
            </a>
          </div>
          <div className="action_btn">
            {rate ? (
              <div className="single_rms">
                <h4>{rate}</h4>
                <a href={bookingButton.url}>{bookingButton.title}</a>
              </div>
            ) : (
              <a href={bookingButton.url}>{bookingButton.title}</a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingOnline
