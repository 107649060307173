import React, { useEffect, useState } from "react"
import upArrow from "../../images/arrow-1.svg"
const BackToTop = () => {
  const [show, setShow] = useState(false)
  const handleScroll = () => {
    if (window.pageYOffset > 550) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }
  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })
  return (
    <>
      {show && (
        <div className="action_back_top">
          <a href="#">
            <img src={upArrow} alt="" />
            Back to Top
          </a>
        </div>
      )}
    </>
  )
}
export default BackToTop
