import React, { useEffect } from "react"
import Layout from "../../components/Layout"
import BookingOnline from "../../components/BookingOnline"
import Aos from "aos"
import BackToTop from "../../components/BackToTop"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"

const EBikes = ({ data }) => {
  useEffect(() => {
    Aos.init()
  }, [])
  const {
    title: pageTitle,
    eBikesPage: {
      title,
      _rawDescription,
      bikeLogo,
      bikeImg,
      bikeTypes,
      images,
    },
  } = data?.sanityPages
  return (
    <>
      <Seo
        title={pageTitle}
        description={"Noyo Harbor offering E-Bikes for customer's."}
      />
      <Layout>
        <div className="header_bottom header_3">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <div className="spa__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="spa_all_content">
                  <EBox
                    data={bikeTypes}
                    title={title}
                    spaDescription={_rawDescription}
                  />
                </div>
              </div>
              <div
                className="col-lg-5"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div className="history_thumb spa">
                  <GatsbyImage
                    image={bikeImg?.asset.gatsbyImageData}
                    alt="Bikes Image"
                    objectFit="cover"
                    objectPosition="center"
                    loading="eager"
                  />
                </div>

                <div className="mt-4">
                  <GatsbyImage
                    image={bikeLogo?.asset.gatsbyImageData}
                    alt="Bike Logo"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rooms__area special_sect">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="room_blk">
                  <div className="row">
                    {images &&
                      images.map((item, index) => (
                        <div className="col-lg-6 col-md-6 col-sm-6" key={index}>
                          <div
                            className="rooms_wrap"
                            data-aos="fade-up"
                            data-aos-delay={`${100 + index * 50}`}
                            data-aos-duration={`${1000 + index * 100}`}
                          >
                            <div className="rooms_thumb spa">
                              <GatsbyImage
                                image={item?.asset.gatsbyImageData}
                                alt="Bike Gallery Image"
                                objectFit="cover"
                                objectPosition="center"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

const EBox = ({ data, title, spaDescription }) => {
  return (
    <>
      <div
        className="spa_blk"
        data-aos="fade-up"
        data-aos-delay={`${50 + 0 * 50}`}
        data-aos-duration={`${1000 + 0 * 100}`}
      >
        <>
          <h3>{title}</h3>
          <div className="spa_blk_description">
            <PortableText value={spaDescription} />
          </div>
        </>
      </div>
      {data.map((item, index) => (
        <div
          className="spa_blk"
          data-aos="fade-up"
          data-aos-delay={`${50 + (index + 1) * 50}`}
          data-aos-duration={`${1000 + (index + 1) * 100}`}
        >
          <h4>{item.title}</h4>
          <p>{item.description}</p>
        </div>
      ))}
    </>
  )
}
export default EBikes
export const bikeData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      eBikesPage {
        title
        _rawDescription
        bikeLogo {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        bikeImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        bikeTypes {
          title
          description
        }
        images {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
      }
    }
  }
`
